import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  Segment,
  Grid,
  Dropdown,
  Button,
  Icon,
  Table,
  Divider,
  Input,
  Label,
  Modal,
  GridColumn,
  Form,
  Checkbox,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FuseCollapsiblePanel from '../../../components/generic/FuseCollapsiblePanel';
import * as QUESTIONAIRE_ACTIONS from "../../../actions/questionaireActions";
import * as EXPORT_ACTIONS from "../../../actions/exportsActions";
import moment from 'moment';
import { Storage } from 'aws-amplify';

const CustomDatePickerInput = React.forwardRef(({ value, onClick }, ref) => (
  <Input
    icon="calendar"
    iconPosition="left"
    placeholder="Select Date"
    onClick={onClick}
    value={value}
    ref={ref}
    style={{ borderRadius: '8px', borderColor: '#ccc' }}
  />
));

class ExportsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedQuestionnaire: "",
      secondarySelectedQuestionnaire: "",
      selectedUserGroup: {},
      selectedSection: "",
      selectedQuestions: [],
      selectedQuestion: "",
      startDate: null,
      endDate: null,
      isModalOpen: false,
      sectionsForSecondaryQ: [],
    };
  }

  hasPermissions(category) {
    return !_.isEmpty(this.props.userPermissions) && this.props.userPermissions.hasOwnProperty(category)
  }

  isAdmin() {
    return this.hasPermissions('Other') && this.props.userPermissions['Other'].actions.admin
  }

  canExtractDBData() {
    return this.hasPermissions('Export Data') && this.props.userPermissions['Export Data'].actions.READ || this.isAdmin()
  }

  canCreateExport() {
    return this.hasPermissions('Export Data') && this.canExtractDBData() && this.props.userPermissions['Export Data'].actions.CREATE || this.isAdmin();
  };

  canDownloadExportData = () => {
    return this.hasPermissions('Export Data') && this.canExtractDBData() && this.props.userPermissions['Export Data'].actions.DOWNLOAD || this.isAdmin();
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.tenant.id, prevProps.tenant.id)) {
      if (this.props.isPermissionsFetched) {
        if (this.canExtractDBData()) {
          this.props.fetchQuestionairesAction(this.props.tenant.id);
          this.props.fetchExportsAction(this.props.tenant.id);
          this.props.fetchUserGroupsAction(this.props.tenant.id);
        } else {
          toast.error('You do not have permission to view extract dashboard data');
        }
      }
    }
  }

  onQanireChange = (event, { value }) => {
    this.setState({ selectedQuestionnaire: value });
  };

  onSecondaryQanireChange = (event, { value }) => {
    this.setState({ secondarySelectedQuestionnaire: value });
    if (value != null) {
      this.props.fetchQuestionaireSectionsAction(value.tenantId, value);
      this.props.fetchQuestionaireQuestionsAction(value.tenantId, value);

      // Open the modal to display sections
      this.setState({ isModalOpen: true });
    }
  };

  onGroupChange = (event, { value }) => {
    this.setState({ selectedUserGroup: value });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, secondarySelectedQuestionnaire: "" });
  };

  onSectionChange = (event, { value }) => {
    if (value != null) {
      this.setState({ selectedSection: value });
    } else {
      this.setState({ selectedSection: "" });
    }
  };

  onSubSectionChange = (event, { value }) => {
    if (value != null) {
      this.setState({ selectedSubSection: value });
    } else {
      this.setState({ selectedSubSection: "" });
    }
  };

  onQuestionChange = (event, { value }) => {
    if (value == null) {
      return
    }
    let sectionName;
    if (this.state.selectedSubSection) {
      sectionName = `${this.state.selectedSubSection.name} (Sub Section)`;
    } else {
      sectionName = this.state.selectedSection.name;
    }

    const newValue = Array.isArray(value) ? value : [value];
    const questionsWithSection = newValue.map(question => ({ ...question, sectionName }));

    const newSelectedQuestions = [...this.state.selectedQuestions, ...questionsWithSection];
    const uniqueSelectedQuestions = [...new Set(newSelectedQuestions)];
    this.setState({ selectedQuestions: uniqueSelectedQuestions });
    this.setState({ selectedQuestion: "" });

  };


  handleDeleteQuestion = (index) => {
    this.setState(prevState => ({
      ...prevState,
      selectedQuestions: prevState.selectedQuestions.filter((_, i) => i !== index)
    }));
  };

  handleSaveExportData = (questionaire, questions, userGroup, startDate, endDate) => {
    if (!_.isEmpty(questionaire)) {
      const selectedQNaire = this.props.questionaires.filter((qnaire) => qnaire.id === questionaire.id).map((qnaire) => ({ name: qnaire.name }));

      let toCommit = {
        questionaireId: questionaire.id,
        questionaireName: questionaire.name,
        questions: questions,
        userGroup: userGroup,
        startDate: startDate ? moment(startDate).format("YYYYMMDD") : "",
        endDate: endDate ? moment(endDate).format("YYYYMMDD") : "",
      };
      this.props.saveExportAction(this.props.tenant, toCommit, true);

      this.setState({
        selectedQuestionnaire: "",
        secondarySelectedQuestionnaire: "",
        selectedUserGroup: {},
        selectedSection: "",
        selectedQuestions: [],
        selectedQuestion: "",
        startDate: null,
        endDate: null,
      });
    }
  };

  handleDownload = async (filename, dateCreated, questionaireName) => {
    try {
      const response = await fetch(await Storage.get(filename));

      if (!response.ok) {
        toast.error('Export Failed to Download. Please try again later.');
        return
      }

      const blob = await response.blob();
      const dataUrl = URL.createObjectURL(blob);

      const formattedDate = moment.utc(dateCreated).format("DD-MM-YYYY")
      const customFilename = `${formattedDate}-${questionaireName}.csv`;

      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = customFilename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error handling download:', error);
    }
  }

  _buildQuestionList(sectionId, sectionName) {
    const { questions, sections } = this.props;

    // Get all subsections for the current section
    const subsections = sections.filter((section) => section.parentSectionId === sectionId);

    const handleCheckboxChange = (question) => {
      const isSelected = this.state.selectedQuestions.find((selected) => selected.id === question.id);

      if (isSelected) {
        // Remove question from selectedQuestions
        this.setState((prevState) => ({
          selectedQuestions: prevState.selectedQuestions.filter((selected) => selected.id !== question.id),
        }));
      } else {
        // Add question to selectedQuestions
        const questionWithSection = { ...question, sectionName };
        this.setState((prevState) => ({
          selectedQuestions: [...prevState.selectedQuestions, questionWithSection],
        }));
      }
    };

    return (
      <div>
        {questions
          .filter((question) => question.sectionId === sectionId)
          .sort((a, b) => a.sequence - b.sequence)
          .map((question) => (
            <Table key={question.id} celled>
              <Table.Body>
                <Table.Row>
                  <Table.Cell style={{ width: '90%' }}>{question.question}</Table.Cell>
                  <Table.Cell style={{ width: '10%' }}>
                    <Checkbox
                      checked={!!this.state.selectedQuestions.find((selected) => selected.id === question.id)}
                      onChange={() => handleCheckboxChange(question)}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          ))}
        {/* Render subsections */}
        {subsections.map((subsection) => (
          <FuseCollapsiblePanel
            key={subsection.id}
            content={this._buildQuestionList(subsection.id, subsection.name)}
            isCollapsed={true}
            leadingIcon="female"
            trailingIcon="angle down"
            header={subsection.name}
          />
        ))}
      </div>
    );
  }

  render() {
    const { selectedQuestionnaire, secondarySelectedQuestionnaire, selectedUserGroup, selectedSection, selectedSubSection, selectedQuestions, startDate, endDate, isModalOpen, sectionsForSecondaryQ } = this.state;
    const { questionaires, exports, sections, questions, userGroups } = this.props;

    const sortedExports = _.orderBy(exports, ["createdAt"], ["desc"]);

    const dropdownOptions = [
      { key: 'none', text: 'None', value: {} },
      ...questionaires
        .filter(questionaire => questionaire.id !== this.state.secondarySelectedQuestionnaire.id && questionaire.isBaseQnaire !== true)
        .map((questionaire) => ({
          key: questionaire.id,
          text: questionaire.name,
          value: questionaire,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    ];

    const qnaireOptions = [
      { key: 'none', text: 'None', value: null },
      ...questionaires
        .filter(questionaire => questionaire.id !== this.state.selectedQuestionnaire.id)
        .map((questionaire) => ({
          key: questionaire.id,
          text: questionaire.name,
          value: questionaire,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    ];

    const sectionOptions = [
      ...sections
        .filter((section) => !section.parentSectionId)
        .sort((a, b) => a.sequence - b.sequence)
        .map((section) => ({
          key: section.id,
          text: section.name,
          value: section,
        })),
    ];

    let questionOptions = [];

    if (selectedSubSection) {
      questionOptions = [
        { key: 'none', text: 'None', value: null },
        ...questions
          .filter((question) => question.sectionId === selectedSubSection.id)
          .filter((question) => !this.state.selectedQuestions.find(selected => selected.id === question.id))
          .sort((a, b) => a.sequence - b.sequence)
          .map((question) => ({
            key: question.id,
            text: question.question,
            value: question,
          })),
      ];
    } else if (selectedSection) {
      questionOptions = [
        { key: 'none', text: 'None', value: null },
        ...questions
          .filter((question) => question.sectionId === selectedSection.id)
          .filter((question) => !this.state.selectedQuestions.find(selected => selected.id === question.id))
          .sort((a, b) => a.sequence - b.sequence)
          .map((question) => ({
            key: question.id,
            text: question.question,
            value: question,
          })),
      ];
    }

    const groupOptions = [
      { key: 'none', text: 'None', value: null },
      ...userGroups
        .map((group) => ({
          key: group.id,
          text: group.name,
          value: group,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),

    ];

    return (
      <Form>
        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <div>
                  <h3>Export Data</h3>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Label style={{ marginBottom: '8px' }}>Main Questionnaire Export:</Label>
                <Dropdown
                  placeholder="Select questionnaire"
                  value={selectedQuestionnaire}
                  onChange={this.onQanireChange}
                  options={dropdownOptions}
                  selection
                  search
                  style={{ width: '100%', borderRadius: '5px' }}
                />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={8}>
                <Label style={{ marginBottom: '8px' }}>Other Questions To Export:</Label>
                <Dropdown
                  placeholder="Select Questionnaire"
                  value={secondarySelectedQuestionnaire}
                  onChange={this.onSecondaryQanireChange}
                  options={qnaireOptions}
                  selection
                  search
                  style={{ width: '100%', borderRadius: '5px' }}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Label style={{ marginBottom: '8px' }}>User Group To Export:</Label>
                <Dropdown
                  placeholder="Select User Group"
                  value={!_.isEmpty(selectedUserGroup) ? selectedUserGroup : null}
                  onChange={this.onGroupChange}
                  options={groupOptions}
                  selection
                  search
                  style={{ width: '100%', borderRadius: '5px' }}
                />
              </Grid.Column>
            </Grid.Row>
            <Modal
              open={isModalOpen}
              onClose={this.closeModal}
              size="small"
            >
              <Modal.Header>Sections for Questionnaire: {this.state.secondarySelectedQuestionnaire.name}</Modal.Header>
              <Modal.Content>
                {sectionOptions.map((section, index) => (
                  <div key={section.key}>
                    <FuseCollapsiblePanel
                      content={this._buildQuestionList(section.key, section.text)}
                      isCollapsed={true}
                      leadingIcon="book"
                      trailingIcon="angle down"
                      header={section.text}
                    />
                    {index !== sectionOptions.length - 1 && <div style={{ marginBottom: '10px' }}></div>}
                  </div>
                ))}
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.closeModal} negative>
                  Close
                </Button>
              </Modal.Actions>
            </Modal>
            {!_.isEmpty(this.state.selectedQuestions) && (
              <>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Label style={{ marginBottom: '8px' }}>Selected Questions:</Label>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '30%' }}>Questionnaire</Table.HeaderCell>
                          <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '30%' }}>Section</Table.HeaderCell>
                          <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '30%' }}>Question</Table.HeaderCell>
                          <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '10%' }}>Remove</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.state.selectedQuestions.map((question, index) => (
                          <Table.Row key={index}>
                            <Table.Cell>{question.questionaireName}</Table.Cell>
                            <Table.Cell>{question.sectionName}</Table.Cell>
                            <Table.Cell>{question.question}</Table.Cell>
                            <Table.Cell>
                              <Button icon='delete' className="danger" compact onClick={() => this.handleDeleteQuestion(index)} />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
            <Divider />
            <Grid.Row>
              <Grid.Column width={3}>
                <Label style={{ marginBottom: '8px' }}>Start Date:</Label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => this.setState({ startDate: date })}
                  endDate={endDate}
                  maxDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  customInput={<CustomDatePickerInput />}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Label style={{ marginBottom: '8px' }}>End Date:</Label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => this.setState({ endDate: date })}
                  startDate={startDate}
                  minDate={startDate}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  customInput={<CustomDatePickerInput />}
                />
              </Grid.Column>
              <Grid.Column width={6}>
                {this.canCreateExport() && (
                  <Button style={{ marginTop: '35px' }} type="submit" className="success" disabled={_.isEmpty(selectedQuestionnaire)} onClick={() => this.handleSaveExportData(selectedQuestionnaire, selectedQuestions, selectedUserGroup, startDate, endDate)}>Create Export</Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Divider />
        <Segment>
          {this.canExtractDBData() && (
            <Button style={{ marginBottom: '8px' }} compact className='primary' icon='refresh' size='small' floated='right' onClick={(e) => {
              this.props.fetchExportsAction(this.props.tenant.id);
            }}></Button>)}
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="nectaPrimaryBg" >Export Created at</Table.HeaderCell>
                <Table.HeaderCell className="nectaPrimaryBg" >Questionnaire Name</Table.HeaderCell>
                <Table.HeaderCell className="nectaPrimaryBg" >Group Exported</Table.HeaderCell>
                <Table.HeaderCell className="nectaPrimaryBg" >Start Date</Table.HeaderCell>
                <Table.HeaderCell className="nectaPrimaryBg" >End Date</Table.HeaderCell>
                <Table.HeaderCell className="nectaPrimaryBg" >Export Status at</Table.HeaderCell>
                <Table.HeaderCell className="nectaPrimaryBg" >Export Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sortedExports.map((exp) => (
                <Table.Row key={exp.id} className='clickable' >
                  <Table.Cell>
                    {this.canDownloadExportData() && (
                      <Button floated='left' icon size='mini' disabled={exp.expStatus !== 'Export Completed'} onClick={() => this.handleDownload(exp.expFilename, exp.createdAt, exp.questionaireName)}>  <Icon name='download' /></Button>
                    )}
                    {moment.utc(exp.createdAt).format("DD-MM-YYYY hh:mm:ss a")}
                  </Table.Cell>
                  <Table.Cell>{exp.questionaireName}</Table.Cell>
                  <Table.Cell>{exp.userGroup?.name}</Table.Cell>
                  {(exp.startDate == null || exp.startDate == '') ? (<Table.Cell>{exp.startDate}</Table.Cell>) : (<Table.Cell>{moment.utc(exp.startDate).format("DD-MM-YYYY ")}</Table.Cell>)}
                  {(exp.endDate == null || exp.endDate == '') ? (<Table.Cell>{exp.endDate}</Table.Cell>) : (<Table.Cell>{moment.utc(exp.endDate).format("DD-MM-YYYY")}</Table.Cell>)}
                  <Table.Cell>{moment.utc(exp.updatedAt).format("DD-MM-YYYY hh:mm:ss a")}</Table.Cell>
                  <Table.Cell>{exp.expStatus}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    exports: _.isEmpty(state.exports.exports) ? [] : Object.values(state.exports.exports),
    userGroups: _.isEmpty(state.exports.exportUserGroups) ? [] : Object.values(state.exports.exportUserGroups),
    questionaires: _.isEmpty(state.exports.exportQnaires) ? [] : Object.values(state.exports.exportQnaires),
    sections: _.isEmpty(state.exports.exportSections) ? [] : Object.values(state.exports.exportSections),
    questions: _.isEmpty(state.exports.exportQuestions) ? [] : Object.values(state.exports.exportQuestions).filter(item => item.isPersonalInfo !== true),

    tenant: _.isEmpty(state.tenantManagement.activeTenant.tenantId) ? {} : state.tenantManagement.activeTenant.tenantId,
    userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
    isPermissionsFetched: state.isPermissionsFetched === true,
    tenant: state.tenantManagement.activeTenant,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchQuestionairesAction: EXPORT_ACTIONS.fetchQuestionairesAction,
    fetchUserGroupsAction: EXPORT_ACTIONS.fetchUserGroupsAction,
    fetchExportsAction: EXPORT_ACTIONS.fetchExportsAction,
    fetchQuestionaireSectionsAction: (tenantId, qnaire) => EXPORT_ACTIONS.fetchQuestionaireSectionsAction(tenantId, qnaire),
    fetchQuestionaireQuestionsAction: (tenantId, qnaire) => EXPORT_ACTIONS.fetchQuestionaireQuestionsAction(tenantId, qnaire),
    saveExportAction: (tenant, exp, isNew) => EXPORT_ACTIONS.saveExportAction(tenant, exp, isNew),
  }
)(ExportsPage);
