import { getWhatsappTemplates } from "../webservices/activationRulesWS";
import { fetchItemsWS } from "../webservices/fetchWS";


export const FETCHING_QUESTION_WHATSAPPTEMPLATES_ACTION = 'FETCHING_QUESTION_WHATSAPPTEMPLATES_ACTION';
export const FETCH_QUESTION_WHATSAPPTEMPLATES_WHATSAPPTEMPLATES_SUCCESS_ACTION = 'FETCH_QUESTION_WHATSAPPTEMPLATES_WHATSAPPTEMPLATES_SUCCESS_ACTION';
export const FETCH_QUESTION_WHATSAPPTEMPLATES_WHATSAPPTEMPLATES_ERROR_ACTION = 'FETCH_QUESTION_WHATSAPPTEMPLATES_WHATSAPPTEMPLATES_ERROR_ACTION';

export const fetchWhatsappTemplatesAction = () => {
  const queryParams = {
    PK: 'WHATSAPPTEMPLATES',
    SK: 'WHATSAPPTEMPLATES'
  };

  return (dispatch, getState) => {
    // dispatch({ type: FETCHING_QUESTION_WHATSAPPTEMPLATES_ACTION });
    // fetchItemsWS('get-dynamodb', queryParams, getState)
    getWhatsappTemplates(getState)
      .then((result) => {
        const returnItems = result.data;
        dispatch({ type: FETCH_QUESTION_WHATSAPPTEMPLATES_WHATSAPPTEMPLATES_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_QUESTION_WHATSAPPTEMPLATES_WHATSAPPTEMPLATES_ERROR_ACTION, err });
      });
  };
}
