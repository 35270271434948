import React, { useState, useEffect } from "react";
import _, { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { Segment, Grid, Dropdown, Input, Container, Form, Button, Label, Icon, Radio, Divider, Checkbox, Card, Modal, Dimmer, Loader, Table } from "semantic-ui-react";
import { toast } from "react-toastify";
import { MapModal } from './MapModal';
import FuseCollapsiblePanel from '../../../components/generic/FuseCollapsiblePanel';

import * as KML_ACTIONS from '../../../actions/kmlUploadActions';
import * as CV_SEARCH_ACTIONS from "../../../actions/cvSearchActions";

export function CVSearch(props) {
    const [state, setState] = useState({
        languages: [],
        qualifications: [],
        searchTerms: [],
        incomeStreams: [],
        fileLocationType: false,
        locationRadius: {
            location: {
                latitude: "",
                longitude: "",
            },
            radius: "5",
        },
    });

    const [searchTerm, setSearchTerm] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [mapModalOpen, setMapModalOpen] = useState(false);
    const [selectedCV, setSelectedCV] = useState(null);
    const [cvSearchQuery, setcvSearchQuery] = useState(null);
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    useEffect(() => {
        if (!_.isEmpty(props.tenant)) {
            props.fetchKmlFilesAction(props.tenant);
        }
    }, [props.tenant]);

    const onSubmit = async () => {
        let payload = {}

        payload["fileLocationType"] = state.fileLocationType ? true : false
        if (state.qualifications.length !== 0)
            payload["qualifications"] = state.qualifications
        if (state.languages.length !== 0)
            payload["languages"] = state.languages

        if (!_.isEmpty(state.searchTerms)) {
            payload["terms"] = state.searchTerms;
        }

        if (
            (state.locationRadius.location.latitude !== '' && state.locationRadius.location.longitude !== '') ||
            (state.locationRadius.location.type === 'KMLFILE' && state.locationRadius.radius !== '')
        ) {
            payload["locationRadius"] = state.locationRadius;
        }


        if (_.isEmpty(payload.qualifications) && _.isEmpty(payload.languages) && _.isEmpty(payload.incomeStreams) && _.isEmpty(payload.terms) && _.isEmpty(payload.locationRadius)) {
            toast.warning("Please fill in some of the filters to help with the search process. Remember to add the term.")
            return
        }
        await props.cvSearchAction(payload, props.tenant, setLoading)
        setcvSearchQuery(payload);
    };

    const handleAddSearchTerm = (e) => {
        e.preventDefault();

        if (searchTerm.trim() !== "") {

            setState(prevState => ({
                ...prevState,
                searchTerms: [...prevState.searchTerms, searchTerm.trim()]
            }));

            setSearchTerm("");
        }
    };

    const handleDeleteSearchTerm = (index) => {
        setState(prevState => ({
            ...prevState,
            searchTerms: prevState.searchTerms.filter((_, i) => i !== index)
        }));
    };

    const handleLanguagesChange = (e, { value }) => {
        setState(prevState => ({
            ...prevState,
            languages: value
        }));
    };

    const handleIncomeStreamChange = (e, { value }) => {
        setState(prevState => ({
            ...prevState,
            incomeStreams: value
        }));
    };

    const handleQualificationsChange = (e, { value }) => {
        setState(prevState => ({
            ...prevState,
            qualifications: value
        }));
    };

    const openModal = (cv) => {
        props.fetchSelectedCVAction(cv, props.tenant, setLoading);
        props.checkCVOwnStatusAction(props.tenant, cv);
        setSelectedCV(cv);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedCV(null);
        setModalOpen(false);
        props.clearUserCV();
    };

    const handleMapSelect = (coordinates) => {
        if (coordinates) {
            const { lat, lng } = coordinates;
            setState((prevState) => ({
                ...prevState,
                locationRadius: {
                    ...prevState.locationRadius,
                    location: {
                        latitude: lat.toString(),
                        longitude: lng.toString()
                    }
                }
            }));
            setMapModalOpen(false);
        }
    };

    const qualificationOptions = [
        { key: uuidv4(), text: "NQF 1 / Grade 9", value: "NQF 1 / Grade 9" },
        { key: uuidv4(), text: "NQF 2 / Grade 10", value: "NQF 2 / Grade 10" },
        { key: uuidv4(), text: "NQF 3 / Grade 11", value: "NQF 3 / Grade 11" },
        { key: uuidv4(), text: "NQF 4 / Grade 12", value: "NQF 4 / Grade 12" },
        { key: uuidv4(), text: "NQF 5 / Higher Certificate", value: "NQF 5 / Higher Certificate" },
        { key: uuidv4(), text: "NQF 6 / Advanced Certificate or National Diploma", value: "NQF 6 / Advanced Certificate or National Diploma" },
        { key: uuidv4(), text: "NQF 7 / Advanced Diploma or Bachelor's Degree", value: "NQF 7 / Advanced Diploma or Bachelor's Degree" },
        { key: uuidv4(), text: "NQF 8 / Postgraduate Certificate or Honour's Degree", value: "NQF 8 / Postgraduate Certificate or Honour's Degree" },
        { key: uuidv4(), text: "NQF 9 / Master's Degree", value: "NQF 9 / Master's Degree" },
        { key: uuidv4(), text: "NQF 10 / Doctoral Degree", value: "NQF 10 / Doctoral Degree" }
    ];

    const languagesOptions = [
        { key: uuidv4(), text: "Zulu", value: "Zulu" },
        { key: uuidv4(), text: "Afrikaans", value: "Afrikaans" },
        { key: uuidv4(), text: "English", value: "English" },
        { key: uuidv4(), text: "Southern Sotho", value: "Southern Sotho" },
        { key: uuidv4(), text: "Swati", value: "Swati" },
        { key: uuidv4(), text: "Tswana", value: "Tswana" },
        { key: uuidv4(), text: "Northern Sotho", value: "Northern Sotho" },
        { key: uuidv4(), text: "Ndebele", value: "Ndebele" },
        { key: uuidv4(), text: "Xhosa", value: "Xhosa" },
        { key: uuidv4(), text: "Tsonga", value: "Tsonga" },
        { key: uuidv4(), text: "SA Sign Language", value: "SA Sign Language" }
    ];

    const kmlFiles = [
        ...props.kmlFiles.map((file) => ({
            key: file.id,
            value: file,
            text: file.fileName,
        }))
    ]

    const _buildSearch = () => {

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={6}>
                        <Form.Field>
                            <label>Qualifications:</label>
                            <Dropdown
                                placeholder='Select Qualifications'
                                fluid
                                multiple
                                search
                                selection
                                options={qualificationOptions}
                                onChange={handleQualificationsChange}
                                value={state.qualifications}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Languages:</label>
                            <Dropdown
                                placeholder='Select Languages'
                                fluid
                                multiple
                                search
                                selection
                                options={languagesOptions}
                                onChange={handleLanguagesChange}
                                value={state.languages}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Form.Field>
                            <label>Add Terms To Search For:</label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    placeholder='Enter Terms'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <Button icon='plus' className='success' onClick={handleAddSearchTerm} style={{ marginLeft: '10px' }} />
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <label>Search Terms:</label>
                            {state.searchTerms.length > 0 &&
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {state.searchTerms.map((term, index) => (
                                        <div key={index} style={{ marginRight: '10px', marginBottom: '5px' }}>
                                            <Label>
                                                {term}
                                                <Icon name='delete' onClick={() => handleDeleteSearchTerm(index)} />
                                            </Label>
                                        </div>
                                    ))}
                                </div>
                            }
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Form.Field>
                            <label>Coordinate Or File:</label>
                            <Checkbox
                                style={{ paddingTop: '10px' }}
                                label="Select File"
                                toggle
                                checked={state.fileLocationType}
                                onChange={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        fileLocationType: !prevState.fileLocationType
                                    }));
                                }}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        {state.fileLocationType == false ? (
                            <Form.Field>
                                <label>Location:</label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                        style={{ paddingRight: '10px' }}
                                        type="number"
                                        icon="map marker"
                                        placeholder="Latitude..."
                                        value={state.locationRadius.location.latitude}
                                        onChange={(event, { value }) => {
                                            if (value >= -90 && value <= 90) {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    locationRadius: {
                                                        ...prevState.locationRadius,
                                                        location: {
                                                            ...prevState.locationRadius.location,
                                                            latitude: value
                                                        }
                                                    }
                                                }));
                                            } else {
                                                toast.warning("Invalid Coordinate! * Latitude should be between -90 and 90.");
                                            }
                                        }}
                                    />
                                    <Input
                                        type="number"
                                        icon="map marker"
                                        placeholder="Longitude..."
                                        value={state.locationRadius.location.longitude}
                                        onChange={(event, { value }) => {
                                            if (value >= -180 && value <= 180) {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    locationRadius: {
                                                        ...prevState.locationRadius,
                                                        location: {
                                                            ...prevState.locationRadius.location,
                                                            longitude: value
                                                        }
                                                    }
                                                }));
                                            } else {
                                                toast.warning("Invalid Coordinate! * Longitude should be between -180 and 180.");
                                            }
                                        }}
                                    />
                                    <Button
                                        icon="map"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setMapModalOpen(true);
                                        }}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Map
                                    </Button>
                                </div>
                            </Form.Field>
                        ) : (
                            <Form.Field>
                                <label>File:</label>
                                <Dropdown
                                    placeholder="Select File"
                                    fluid
                                    selection
                                    search
                                    options={
                                        [...props.kmlFiles]
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map(file => ({
                                                key: file.id,
                                                text: file.name,
                                                value: file
                                            }))
                                    }
                                    onChange={(event, data) => {
                                        const selectedFile = data.value;
                                        setState(prevState => ({
                                            ...prevState,
                                            locationRadius: {
                                                ...prevState.locationRadius,
                                                location: selectedFile
                                            }
                                        }));
                                    }}
                                />
                            </Form.Field>
                        )}
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form.Field>
                            <label>Radius (KM):</label>
                            <Input
                                type="number"
                                icon="map"
                                placeholder="Radius..."
                                value={state.locationRadius.radius}
                                onChange={(event, { value }) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        locationRadius: {
                                            ...prevState.locationRadius,
                                            radius: value
                                        }
                                    }));
                                }}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    const _buildCVBoard = () => {
        const { cv } = props;

        if (_.isEmpty(cv.data)) {
            return (
                <Segment style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '200px' }}>
                    <Icon name="search" size="huge" />
                    <Label style={{ marginTop: '10px', textAlign: 'center' }}>Perform a search to view potential CVs</Label>
                </Segment>
            );
        }


        const renderCards = () => {
            // Sort the cv.data array by _score in descending order
            const sortedCvData = cv.data.sort((a, b) => b._score - a._score);

            return sortedCvData.map((cvItem, index) => {
                let percentage = Math.round(((cvItem._score - 0) / (1 - 0)) * 100 / 5) * 5;

                percentage = Math.min(Math.max(0, percentage), 100);

                let headerColor;
                if (percentage == 100) {
                    headerColor = '#3A95A4';
                } else if (percentage > 80) {
                    headerColor = 'green';
                } else if (percentage > 50) {
                    headerColor = '#FFA500';
                } else {
                    headerColor = 'red';
                }

                return (
                    <Grid.Column key={cvItem._id} style={{ padding: '10px' }}>
                        <Card onClick={() => openModal(cvItem)}>
                            <Card.Content>
                                <Card.Header style={{ paddingBottom: "10px" }}>
                                    <span style={{
                                        backgroundColor: headerColor,
                                        color: 'white',
                                        borderRadius: '50%',
                                        padding: '8px',
                                    }}>{percentage}% </span>
                                </Card.Header>
                                <Card.Meta>Match based on search query.</Card.Meta>
                            </Card.Content>
                            <Card.Content extra>
                                CV ~ {index + 1}
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                );
            });
        };


        return (
            <div>
                {loading && (
                    <Dimmer active inverted>
                        <Loader>Loading...</Loader>
                    </Dimmer>
                )}
                <Segment>
                    <Grid columns={4}>
                        <Grid.Row>
                            {renderCards()}
                        </Grid.Row>
                    </Grid>
                    {renderCVModal()}
                </Segment>
            </div>
        );
    };

    const renderCVModal = () => {
        if (!selectedCV || !props.userCV?.length > 0) return null;

        const { cvMainSections, cvQuestions, boughtCv, userAnswers } = props;
        const isCVBought = boughtCv.length > 0;

        // Filter out sections where all questions do not have answers
        const filteredSections = cvMainSections.filter((section) =>
            cvQuestions.some(
                (question) =>
                    question.sectionId === section.id &&
                    userAnswers.some((answer) => answer.questionId === question.id)
            )
        );

        return (
            <Modal open={modalOpen} onClose={closeModal} size="large">
                <Modal.Header>User Details:</Modal.Header>
                <Modal.Content>
                    {filteredSections
                        .sort((a, b) => a.sequence - b.sequence)
                        .map((section, index) => (
                            <div key={section.id}>
                                <FuseCollapsiblePanel
                                    content={_buildQuestionList(section, section.name)}
                                    isCollapsed={true}
                                    leadingIcon="book"
                                    trailingIcon="angle down"
                                    header={section.name}
                                />
                                {index !== cvMainSections.length - 1 && (
                                    <div style={{ marginBottom: "10px" }}></div>
                                )}
                            </div>
                        ))}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        className="success"
                        loading={loading}
                        disabled={isCVBought}
                        onClick={(e) => {
                            e.preventDefault();
                            if (!isCVBought) {
                                props.purchaseCVAction(selectedCV, cvSearchQuery, setLoading);
                                closeModal();
                            }
                        }}
                    >
                        {isCVBought ? "CV already owned" : "Purchase CV"}
                    </Button>
                    <Button onClick={closeModal} className="warning">
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    const _buildQuestionList = (section, sectionName) => {
        const { cvQuestions, cvSubSections, userAnswers } = props;

        const subsections = cvSubSections.filter(
            (subsection) =>
                subsection.parentSectionId === section.id &&
                cvQuestions.some(
                    (question) =>
                        question.sectionId === subsection.id &&
                        userAnswers.some((answer) => answer.questionId === question.id)
                )
        );

        const sectionQuestions = cvQuestions.filter(
            (question) =>
                question.sectionId === section.id &&
                userAnswers.some((answer) => answer.questionId === question.id)
        );

        let combinedQuestionAnswers = sectionQuestions.flatMap((question) => {
            const relatedAnswers = userAnswers.filter(
                (answer) => answer.questionId === question.id && answer.values
            );

            return relatedAnswers.length > 0
                ? relatedAnswers.map((answer) => ({
                    question,
                    answer,
                }))
                : [{ question, answer: null }];
        });

        // Updated sorting logic
        combinedQuestionAnswers.sort((a, b) => {
            const repeatGroupA = a.answer?.repeatGroup;
            const repeatGroupB = b.answer?.repeatGroup;

            // If both items have repeatGroup, sort by repeatGroup first
            if (repeatGroupA && repeatGroupB) {
                if (repeatGroupA !== repeatGroupB) {
                    return repeatGroupA - repeatGroupB;
                }
                return a.question.sequence - b.question.sequence;
            }

            // If only one of the items has repeatGroup, place the one without repeatGroup later
            if (!repeatGroupA && repeatGroupB) {
                return 1;  // Place b before a
            } else if (repeatGroupA && !repeatGroupB) {
                return -1; // Place a before b
            }

            // If neither has repeatGroup, just sort by sequence
            return a.question.sequence - b.question.sequence;
        });


        if (sectionQuestions.length === 0 && subsections.length === 0) {
            return null;
        }

        return (
            <div>
                {combinedQuestionAnswers.map(({ question, answer }, index) => (
                    <div key={`${question.id}-${index}`}>
                        <Table celled>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell style={{ width: "90%" }}>
                                        Q: {question.question}
                                    </Table.Cell>
                                </Table.Row>
                                {answer ? (
                                    <Table.Row>
                                        <Table.Cell
                                            style={{
                                                width: "90%",
                                                backgroundColor:
                                                    question.questionType !== "upload_image"
                                                        ? "#b7e0b5"
                                                        : "transparent",
                                            }}
                                        >
                                            A: {answer.values}
                                        </Table.Cell>
                                    </Table.Row>
                                ) : (
                                    <Table.Row>
                                        <Table.Cell
                                            style={{ width: "90%", backgroundColor: "#e0e0e0" }}
                                        >
                                            No answer provided.
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                        {index !== combinedQuestionAnswers.length - 1 && (
                            <div style={{ marginBottom: "10px" }}></div>
                        )}
                    </div>
                ))}

                <div style={{ marginBottom: "10px" }}></div>

                {subsections.map((subsection, index) => (
                    <div key={subsection.id}>
                        <FuseCollapsiblePanel
                            key={subsection.id}
                            content={_buildQuestionList(subsection, subsection.name)}
                            isCollapsed={true}
                            leadingIcon="book"
                            trailingIcon="angle down"
                            header={subsection.name}
                        />
                        {index !== subsections.length - 1 && (
                            <div style={{ marginBottom: "10px" }}></div>
                        )}
                    </div>
                ))}
            </div>
        );
    };


    return (
        <Container fluid>
            <h3>CV Search:</h3>
            <Segment>
                <Form
                    size="medium"
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ paddingBottom: "25px" }}
                >
                    {_buildSearch()}
                    <MapModal
                        open={mapModalOpen}
                        onClose={() => setMapModalOpen(false)}
                        onSelectCoordinates={handleMapSelect}
                        selectedPos={state.locationRadius.location}
                    />
                    <Divider />
                    <Button.Group floated="right">
                        <Button type="submit" className="success" loading={loading}>
                            <Icon name="checkmark" /> Search
                        </Button>
                    </Button.Group>
                </Form>
            </Segment>

            {_buildCVBoard()}
        </Container>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        cv: state.cvSearch.cvSearch,
        userCV: state.cvSearch.userCV,
        tenant: state.tenantManagement.activeTenant,
        kmlFiles: state.kmlFiles,
        boughtCv: state.cvSearch.boughtCV,
        cvMainSections: state.cvSearch.userCV?.filter(item => item.type === 'SECTION' && !item.parentSectionId),
        cvSubSections: state.cvSearch.userCV?.filter(item => item.type === 'SECTION' && item.parentSectionId),
        cvQuestions: state.cvSearch.userCV?.filter(item => item.type === 'QUESTION' && item.questionType != "no_answer" && item.isPersonalInfo != true && item.whatsAppOnly != true),
        userAnswers: state.cvSearch.userCVAnswers.filter(item => item.type === 'ANSWER'),
    };
}

export default connect(
    // map state to props
    mapStateToProps,
    // map dispatch to props
    (dispatch) => ({
        cvSearchAction: (payload, tenant, setLoading) => dispatch(CV_SEARCH_ACTIONS.cvSearchAction(payload, tenant, setLoading)),
        fetchKmlFilesAction: (tenant) => dispatch(KML_ACTIONS.fetchKmlFilesAction(tenant)),
        fetchSelectedCVAction: (cv, tenant, setLoading) => dispatch(CV_SEARCH_ACTIONS.fetchSelectedCVAction(cv, tenant, setLoading)),
        checkCVOwnStatusAction: (tenant, cv) => dispatch(CV_SEARCH_ACTIONS.checkCVOwnStatusAction(tenant, cv)),
        purchaseCVAction: (cv, cvSearchQuery, setLoading) => dispatch(CV_SEARCH_ACTIONS.purchaseCVAction(cv, cvSearchQuery, setLoading)),
        clearUserCV: () => dispatch(CV_SEARCH_ACTIONS.clearUserCV()),
    })
)(CVSearch);
