import React from 'react';
import { connect } from 'react-redux';
import Amplify, { Auth } from 'aws-amplify';
import aws_exports from '../aws-exports';
import { Container, Label } from 'semantic-ui-react';
import { AmplifyAuthenticator, AmplifyGreetings, AmplifySignIn, AmplifyNav, AmplifyCheckbox, AmplifySignUp } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import "./App.css";
import MainAppComponent from './MainAppComponent';
import logo from '../media/wakamoso.svg'
import TopMenu from './TopMenu';
import * as REGISTRATION_ACTIONS from '../actions/registrationActions';
import _ from "lodash";
import { func } from 'prop-types';

Amplify.configure(aws_exports);

const App = (props) => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  const [userName, setUserId] = React.useState(null);
  const [groupName, setGroupName] = React.useState(null);
  const hasConfirmSignUp = React.useRef(false);
  const hasSignedIn = React.useRef(false);
  const [formState, setFormState] = React.useState({
    firstname: '',
    lastname: '',
    username: '',
    password: '',
    email: '',
    tenantName: '',
    tenantDescription: '',
  });

  function isBeforeToday(date) {
    return new Date(date).valueOf() < new Date().valueOf();
  }

  function checkTokenValidity(authData) {
    const hasToken = !_.isEmpty(authData?.signInUserSession?.accessToken);
    if (hasToken) {
      const exp = authData?.signInUserSession?.accessToken?.payload?.exp ?? 0;
      const expDate = new Date(exp * 1000);
      return !isBeforeToday(expDate);
    }
    return false;
  }

  // Function to handle sign-out
  async function handleSignOut(authData) {
    const hasValidToken = checkTokenValidity(authData);
    if ((authState === 'signedout' || !hasValidToken) && hasSignedIn.current === true) {
      hasSignedIn.current = false;
      try {
        await Promise.all([
          props.resetState(),
          Auth.signOut()
        ]);

        window.location.href = "/";
      } catch (error) {
        console.error("Error during sign-out:", error);
      }
    }
  }

  React.useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      try {
        setAuthState(nextAuthState);
        setUser(authData);
        setUserId(authData?.signInUserSession?.accessToken?.payload?.username ?? "");
        const groups = authData?.signInUserSession?.accessToken?.payload['cognito:groups'];
        if (groups && groups.length > 0) {
          setGroupName(groups[0]);
        } else {
          setGroupName(null);
        }

        handleSignOut(authData);

      } catch (e) {
        console.error(e);
      }
    });

    return () => {
      setUserId(null);
      setGroupName(null);
    };
  }, [authState]);


  // Uncomment this function to enable auto sign-out
  // React.useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (user) {
  //       handleSignOut(user);
  //     }
  //   }, 60000); // Check every 30 seconds

  //   return () => clearInterval(intervalId);
  // }, [user]);

  if (userName && groupName && authState === 'signedin' && hasSignedIn.current === false) {
    hasSignedIn.current = true;
    const payload = {
      userName,
      groupName,
    };
    props.saveAuthDataAction(user);
    props.fetchAuthUserRoleAction(payload);
    props.tenantChecksAction(payload);
  }

  // Function to handle custom form input changes -> chack lambda attached to cognito to see where the values are being used
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('custom:')) {
      const attributeName = name.substring('custom:'.length);
      setFormState({
        ...formState,
        [name]: value,
        [attributeName]: value,
      });
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  return authState === AuthState.SignedIn && user ? (
    <div className='App' fluid style={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
      <div style={{ flexBasis: '15%', flexShrink: 0, paddingTop: '30px', height: '100%', overflow: 'auto' }}>
        <Label style={{ position: "absolute", top: "0", right: "10", color: "#3A95A4", fontSize: "15px" }}>
          {process.env.REACT_APP_ENV.toUpperCase()} Version: {process.env.REACT_APP_VERSION}
        </Label>
        <div>
          <img style={{ paddingLeft: '15px' }} slot="logo" src={logo} alt="Wakamoso Logo" height="100" />
        </div>
        <div style={{ padding: '10px' }}>
          <Label style={{ color: "#3A95A4", fontSize: "15px", backgroundColor: "transparent" }} size='large' slot="greetings-message" icon='user circle'>
            {`Welcome, ${user.username}`}
          </Label>
        </div>
        <div style={{ paddingLeft: '15px' }}>
          <TopMenu />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'sticky' }}>
          <AmplifyGreetings style={{ backgroundColor: "white", zIndex: 1000 }} />
        </div>
      </div>

      <div style={{ flexBasis: '85%', height: '100%', overflow: 'auto' }}>
        <MainAppComponent />
      </div>
    </div>

  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn headerText="Admin Platform" slot="sign-in">
        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px' }} slot="header-subtitle" src={logo} alt="Wakamoso Logo" height="100" />
      </AmplifySignIn>
      <AmplifySignUp
        headerText="Admin Platform"
        slot="sign-up"
        formFields={[
          {
            type: 'custom:tenantName',
            label: 'Tenant Name *',
            placeholder: 'Enter tenant name',
            required: true,
            handleInputChange: handleInputChange,
            value: formState.tenantName,
            inputProps: {
              name: 'custom:tenantName',
              pattern: '[\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+',
              title: 'Only letters, numbers, and common symbols are allowed. *Spaces not allowed.', // Validation message
            },
          },
          {
            type: 'custom:tenantDescription',
            label: 'Tenant Description *',
            placeholder: 'Enter tenant description',
            required: true,
            handleInputChange: handleInputChange,
            value: formState.tenantDescription,
            inputProps: { name: 'custom:tenantDescription' },
          },
          {
            type: 'custom:firstname',
            label: 'Name *',
            placeholder: 'Enter your name',
            required: true,
            handleInputChange: handleInputChange,
            value: formState.firstname,
            inputProps: { name: 'custom:firstname' },
          },
          {
            type: 'custom:lastname',
            label: 'Surname *',
            placeholder: 'Enter your surname',
            required: true,
            handleInputChange: handleInputChange,
            value: formState.lastname,
            inputProps: { name: 'custom:lastname' },
          },
          {
            type: 'username',
            label: 'Username *',
            placeholder: 'Enter your username',
            required: true,
            handleInputChange: handleInputChange,
            value: formState.username,
            inputProps: { name: 'username' },
          },
          {
            type: 'password',
            label: 'Password *',
            placeholder: 'Enter your password',
            required: true,
            handleInputChange: handleInputChange,
            value: formState.password,
            inputProps: { name: 'password' },
          },
          {
            type: 'email',
            label: 'Email *',
            placeholder: 'Enter your email',
            required: true,
            handleInputChange: handleInputChange,
            value: formState.email,
            inputProps: { name: 'email' },
          },
        ]}
      >
        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px' }} slot="header-subtitle" src={logo} alt="Wakamoso Logo" height="100" />
      </AmplifySignUp>
    </AmplifyAuthenticator>
  );
}

const mapStateToProps = (state) => {
  return {
    tenantId: state.tenantManagement.activeTenant,
    tenantRole: state.tenantRole,
    authData: state.authData,
  };
}

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
    fetchSourceTenantAction: (payload) => dispatch(REGISTRATION_ACTIONS.fetchSourceTenantAction(payload)),
    fetchAuthUserRoleAction: (payload) => dispatch(REGISTRATION_ACTIONS.fetchAuthUserRoleAction(payload)),
    // saveTenantAction: (payload) => dispatch(REGISTRATION_ACTIONS.tenantSaveAction(payload)),
    tenantChecksAction: (payload) => dispatch(REGISTRATION_ACTIONS.tenantChecksAction(payload)),
    saveAuthDataAction: (payload) => dispatch(REGISTRATION_ACTIONS.saveAuthDataAction(payload)),
    resetState: () => dispatch(REGISTRATION_ACTIONS.resetState()),
  })
)(App);